<!--在线电子合同-->
<template>
	<div :class="themeClass" class="width-fill bg-F8F9FE">
		<div class="min-width1100 max-width1100 margin-lr-auto box-sizing">
			<div class="flex-row">
				<!-- 左边 -->
				<div :class="themeClass" class="pt20">
					<!-- 在线电子合同的left -->
					<div class="leftbox">
						<div class="leftTitle">线上合同</div>
						<div v-for="(item,index) of mainitems" :key="index">
							<div class="leftText padding-l-10" :class="selectId==item.ids?'color-theme leftActive':''"
								@click="getUrl(item)">
								{{item.fName}}
							</div>
						</div>
					</div>
					<!-- 在线电子合同的left -->
				</div>
				<!-- 左边 -->
				<div class="right-box  padding-lr-20 bg-fff pt20" v-if='showName == "申请线上合同"'>
					<!-- 步骤条 -->
					<div style="display: flex;justify-content: center;">
						<div class='contract-step margin-t-20'>
							<el-steps :active="active" :align-center="true" finish-status="success">
								<el-step title="选择商品"></el-step>
								<el-step title="填写信息"></el-step>
								<el-step title="审核"></el-step>
							</el-steps>
						</div>
					</div>
					<!-- 步骤条 -->
					<!--表格 -->
					<shopType v-if="active == 1" @change="changeShopType"/>
					<information v-if="active == 2" :shopTypecheckData="shopTypecheckData"  :historyID="historyID"
						@change="changeinformation" />
					<div v-if="active == 3" >
						<!-- 审核中 -->
						<div class="margin-t-30 ">
							<div style="display: flex;justify-content: center;">
								<img src="../../../assets/shenhezhong.png" alt="" style=" width:223px;height:178px;" >
							</div>
							<div class="size-16">正在审核中...</div>
							<div class="size-14">我们将尽快为你审核，请耐心等待！</div>
							<div class="btn margin-t-30">
								<el-button   @click="ClickHistory">查看历史合同</el-button>
							</div>
						</div>
					</div>
					<!-- 表格 -->
				</div>
				<!-- 历史申请合同 -->
				<div class="right-box  padding-lr-20 bg-fff pt20" v-if="showName == '历史申请合同'">
					<history @change="changeHistory" v-if='historyShow' />
				</div>
				<!-- 历史申请合同 -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import shopType from './shopType'; //选择商品页面
	import information from './information'; //填写信息
	import history from './history'; //历史申请合同
	export default {
		components: {
			shopType,
			information,
			history,
		},
		data() {
			return {
				mainitems: [{
						fName: '申请线上合同',
						ids: 0
					},
					{
						fName: '历史申请合同',
						ids: 1
					},
				],
				selectId: 0, //控制颜色和背景色的拜年话
				active: 1, //设置当前激活步骤
				showName: "申请线上合同", //控制右边的显示与隐藏
				shopTypecheckData: [],
				historyID:"", //接收历史合同传过来的id
				historyShow:true,
				
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			//历史申请合同传回来数据
			changeHistory(activeHis){
				console.log(activeHis)
		        if(activeHis){
					this.selectId = 0;
					this.showName = '申请线上合同';
					this.active = 1
				}
				},
			//点击查看历史合同
			Clickhistory(){
				this.selectId = 1;
				this.showName = '历史申请合同'
			},
			// 点击查看历史合同
			ClickHistory(){
				this.selectId = 1;
				this.showName = '历史申请合同';
				this.active = 1
			},
			getUrl(val) {
				this.selectId = val.ids;
				this.showName = val.fName
				if(this.selectId == 1){
					this.historyShow =true
				}
				if(this.selectId == 0){
					this.active=1
				}
			},
			//第一步的回调
			changeShopType(e, checkData) {
				this.active = e
				if (checkData) {
					this.shopTypecheckData = checkData
				}
			},
			// 填写信息的回调
			changeinformation(e) {
				this.active = e
			},
			//表格斑马线
			tableRowClassName(rowIndex) {
				if (rowIndex.rowIndex % 2 === 0) {
					return 'warning-row'
				} else if (rowIndex.rowIndex % 2 === 1) {
					return 'success-row'
				}
			},
			// 行复选框选中事件
			changeCheckBoxRow(selection) {
				if (selection.length > 0) {
					this.checkData = selection;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	// left
	.leftTitle {
		line-height: 40px;
		font-size: 16px;
		text-align: center;
		background-color: #f2f2f2;
	}

	.leftbox {
		background-color: #ffffff;
		border-right: 0;
		min-height:750px;
		min-width: 170px;
		padding-bottom: 5px;
	}

	.leftText {
		font-weight: normal;
		text-align: left;
		font-size: 14px;
		line-height: 40px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.leftActive {
		background: #ddebff;
		color: #0173FE;
		font-weight: 700;
	}

	.right-box {
		width: calc(1100px - 220px);
	}

	/* 步骤条 */
	.contract-step {
		height: 111px;
		width: 619px;
		box-sizing: border-box;
	}

	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		font-size: 14px;
	}

	::v-deep .el-step__title.is-success {
		font-weight: 400;
		font-size: 14px;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
	}

	.size-16 {
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		line-height: 70px;
	}

	.size-14 {
		font-size: 14px;
		text-align: center;
	}
	.btn {
		display: flex;
		justify-content: center;
	
		/deep/.el-button {
			padding: 12px 150px !important;
			border-color:#3366FF;
			color:#3366FF ;
		}
	}
	.failBtn{
		display: flex;
		justify-content: center;
	
		/deep/.el-button {
			padding: 12px 70px !important;
		}
	
		/deep/.el-button--primary {
			background-color: #366CFC;
			border-color: #409EFF;
		}
	}
	
</style>
