<template>
	<!-- 填写信息组件 -->
	<div  v-loading="tableLoading" ref="multipleTable" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading">
		<div class="titleText margin-b-10">填写商品数量</div>
		<el-table :data="tableData" max-height="100vh" border @selection-change="changeCheckBoxRow"
			class="LRcustomer-table" :row-class-name="tableRowClassName" :highlight-current-row="true"
			:header-cell-style="{ 'text-align': 'center', background: '#f2f4fd',height: '20px',padding:'6px 0', }"
			header-align="center">
			<el-table-column label="商品" align="left">
				<template slot-scope="scope">{{ scope.row.fGoodsFullName }}</template>
			</el-table-column>
			<el-table-column label="包装" align="center" width="80">
				<template slot-scope="scope">{{ scope.row.fGoodsPackingName}}</template>
			</el-table-column>
			<el-table-column label="当前价" align="center" width="160">
				<template slot-scope="scope">
					<span
						v-if='scope.row.fGoodsMinPrice!="" &&scope.row.fGoodsMinPrice == scope.row.fGoodsPrice ? false:true'>{{scope.row.fGoodsMinPrice |NumFormat}}~</span>{{scope.row.fGoodsPrice |NumFormat}}
				</template>
			</el-table-column>
			<el-table-column label="数量(吨)" align="center" width="160">
				<template slot-scope="scope">
					{{scope.row.precision}}
					<el-input-number size="small" v-model="scope.row.fAmount" :min="1" :precision="2" :step="1"
						@change="valChange(scope.row,scope.$index)" :max="99999" label="描述文字" class="inputNumber"></el-input-number>
				</template>
			</el-table-column>
		</el-table>
		<!-- 其他信息 -->
		<div>
			<div class="titleText margin-b-10 margin-t-10">
				其他信息
			</div>
			<div class="" style="box-sizing: border-box;width: 50%;">
				<el-form ref="form" :rules="rules" :model="form" label-width="80px">
					<el-row :gutter="20">
						<el-col :span="24">
							<el-form-item label="送达地址" prop="fAddress">
								<el-input v-model="form.fAddress" placeholder="请输入送达地址" size="medium" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="联系人" prop="fContact">
								<el-input v-model="form.fContact" placeholder="请输入联系人" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="联系电话" prop="fPhone">
								<el-input v-model="form.fPhone" placeholder="请输入联系电话" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="微信号码" prop="fWxCode">
								<el-input v-model="form.fWxCode" placeholder="请输入微信号码" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="电子邮箱" prop="fElecMail">
								<el-input v-model="form.fElecMail" placeholder="请输入电子邮箱" />
							</el-form-item>
						</el-col>
					</el-row>

				</el-form>

			</div>
		</div>
		<!-- 其他信息 -->
		<div style="display: flex;justify-content: center;">
			<div class="btn padding-tb-20">
				<el-button type="primary" @click="clickupper">上一步</el-button>
				<el-button type="primary" @click="submitForm">提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			shopTypecheckData: {
				type: Array,
				default: [],
			},
			historyID: {
				type: String,
				default: "",
			}
		},
		data() {
			return {
				tableLoading: false, //表格加载动画
				tableData: [], //接受表格的数据
				numfAmount: 1,
				checkData: [],
				form: {
					fAddress: "", //送达地址
					fContact: "", //联系人
					fPhone: "", //联系电话
					fWxCode: "", //微信号码
					fElecMail: "", //电子邮箱
					fDetail: []
				},
				rules: {
					fAddress: [{
						required: true,
						message: '请输入送达地址',
						trigger: 'blur'
					}],
					fContact: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					fPhone: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
						message: '请输入正确的11位手机号码或使用-的座机号码',
						trigger: "blur"
					}],
					fWxCode: [{
						required: true,
						message: '请输入微信号码',
						trigger: 'blur'
					}],
					fElecMail: [{
							required: true,
							message: '请输入电子邮箱',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
							message: '请输入正确电子邮箱',
							trigger: "blur"
						}
					],
				},
				active: 0,
				ContractApproveStatus: "", //接受摘要的数据
			}
		},
		  // 2：需要在退出页面的时候销毁这个监听事件
		  destroyed() {
		    window.removeEventListener("popstate",this.clickupper, false);
		  },
		mounted() {
			if (this.shopTypecheckData.length != 0) {
				this.shopTypecheckData.forEach(item => {
					item.fAmount = 1
				})
				this.tableData = JSON.parse(JSON.stringify(this.shopTypecheckData))
			}
			if (this.historyID != "") {
				this.getContract()
			}
			if (window.history) {
			      history.pushState(null, "", document.URL); //这里有没有都无所谓，最好是有以防万一
			      window.addEventListener("popstate", this.clickupper, false); // 回退时执行
				}
		},
		methods: {
			//数量加减
			valChange(item, i) {
				console.log(item, i)
				if (!item.fAmount) {
					console.log(this.tableData)
					this.tableData[i].fAmount = 0
				}
			},
			// 获取回显的摘要数据
			getContract() {
				this.ApiRequestPostNOMess('/api/mall/ebsale/elec-contract/get', {
					id: this.historyID
				}).then(result => {
					if (result.obj) {
						this.ContractApproveStatus = result.obj.fApproveStatus;
						this.form = result.obj
					}
					this.getelecContractDetail(this.historyID)
				})
			},
			// 预览明细
			getelecContractDetail(id) {
				this.ApiRequestPostNOMess('/api/mall/ebsale/elec-contract-detail/get-list', {
					fElecContractID: id
				}).then(result => {
					this.tableData = result.obj.items;
				})
			},
			submitForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.clicksubmit()
					} else {
						return false;
					}
				});
			},
			//点击提交 
			clicksubmit() {
				this.tableLoading =true;
				let params = [];
				for (let item of this.tableData) {
					let data = {
						fAmount: item.fAmount,
						fMoneyTax: Number(item.fAmount) * Number(item.fGoodsPrice),
						fGoodsID: item.fGoodsID,
						fGoodsPriceID: item.fGoodsPriceID
					}
					params.push(data)
				}
				this.ApiRequestPost('/api/mall/ebsale/elec-contract/create', {

					fAddress: this.form.fAddress,
					fContact: this.form.fContact,
					fPhone: this.form.fPhone,
					fWxCode: this.form.fWxCode,
					fElecMail: this.form.fElecMail,
					fDetail: params,
					fRemark:""
				}).then(result => {
					this.active = 3;
					this.$emit("change", this.active)
					this.tableLoading =false;
				},
				Error=>{
					this.tableLoading =false;
				})
			},
			clickupper(){
					this.active = 1
			        this.$router.push({
			          path:"/ElectronicContract",
					  params: {
					  	active: this.active,
					  },
			        })
				this.$emit("change", this.active)
	
			    },
			// 点击上一步按钮
			// clickupper() {
			// 	this.active = 1
			// 	this.$emit("change", this.active)
			// },
			//表格斑马线
			tableRowClassName(rowIndex) {
				if (rowIndex.rowIndex % 2 === 0) {
					return 'warning-row'
				} else if (rowIndex.rowIndex % 2 === 1) {
					return 'success-row'
				}
			},
			// 行复选框选中事件
			changeCheckBoxRow(selection) {
				if (selection.length > 0) {
					this.checkData = selection;
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	/deep/.el-table .cell {
		font-weight: normal;
		color: #000000;
	}
	/deep/.el-table--border th.gutter:last-of-type{
		background-color: #f2f4fd;
	}
	.el-table {
		display: flex;
		flex-direction: column;
	}

	/deep/.el-table__header-wrapper {
		overflow: visible !important;
	}

	.LRcustomer-table /deep/thead tr th.is-leaf {
		border-right: none;
		border-bottom: none;
		border-right: none;
	}

	.el-table--border,
	.el-table--group {
		border: none;
	}

	.el-table--border::after,
	.el-table--group::after {
		width: 0;
	}

	.LRcustomer-table::before {
		width: 0;
	}

	.LRcustomer-table .el-table__fixed-right::before,
	.el-table__fixed::before {
		width: 0;
	}

	.LRcustomer-table .el-table td,
	.el-table th.is-leaf {
		// border-right: none;
		border-right: transparent;
	}

	.table-wrapper {
		height: calc(100% - 60px);
	}

	/deep/.el-table--border td,
	.el-table--border th,
	.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: transparent !important;
		z-index: 10;
	}

	.titleText {
		font-size: 14px;
		font-weight: 700;
	}

	.btn {
		display: flex;
		justify-content: space-around;

		/deep/.el-button {
			padding: 12px 90px !important;
		}

		/deep/.el-button--primary {
			background-color: #366CFC;
			border-color: #409EFF;
		}
	}

	::v-deep .dataspicker>>>.el-picker-panel__link-btn {
		display: none !important;
	}

	.width-100 {
		width: 100%;
	}

	/deep/.el-table td,
	.el-table th {
		padding: 5px 0;
	}

	/deel/.el-table td,
	.el-table th {
		padding: 5px 0;
	}

	/deep/.el-table--border td,
	.el-table--border th,
	.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: none;
	}

	/deep/.el-table--border td,
	.el-table--border th,
	.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: none;
	}

	// 计数器的样式
	.inputNumber {
		// /deep/.el-input__inner{
		//  background:red
		// }
		// /deep/.el-input-number__decrease, .el-input-number__increase{
		//   background:red !important;
		// z-index:9999 !important;

		// }
		//  /deep/.el-input-number--small .el-input-number__decrease, .el-input-number--small .el-input-number__increase{
		//    background:red ;
		// z-index:10
		//  }
		// /deep/.el-input-number__decrease, .el-input-number__increase{
		//  // position:static;
		//  background:red !important;
		//  z-index:-5 !important
		// }
	}
</style>
