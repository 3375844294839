<template>
	<!--表格 -->
	<div v-loading="boxloading" element-loading-text="拼命加载中" :row-key="getRowKey"
		element-loading-spinner="el-icon-loading">
		<!--导航-->
		<div class="shopName margin-b-10">自营店商品</div>
		<div class="margin-b-10  radius5 font-size12" style="border: 1px solid #eeedf2;">
			<div v-for="(item, index) of classifyList" :key="index" style="display: flex"
				:class="classifyList.length != index + 1 ? 'select-box' : ''">
				<div class="select-left bg-F2F4FD margin-r-20 flex-row-center-center">
					<div class="margin-tb-auto">{{ item.fClassPropertie }}</div>
				</div>
				<div class="select-right  padding-t-10">
					<div class="flex-row-wrap">
						<div class="right-box pointer-color-theme  margin-b-10 color666" 
							:class="{'color-theme': item1.selected == true,}"
							v-for="(item1, indexw) of item.goodsClassPropertieDatas" :key="'data' + indexw"
						    >
							 <el-checkbox-group   v-if="item.manyShow" v-model="selectedList" >
							    <el-checkbox   v-if="item.manyShow" style="margin-right: 10px;" 
								:label="item1.fClassPropertieDataID" >
								{{item1.fClassPropertieData}}
							    </el-checkbox> 
							  </el-checkbox-group >
							 <span style="width: 70px;display: inline-block;min-width: 70px;" 	@click="cate3SelectClick(item1)" v-else>{{ item1.fClassPropertieData }}</span>
						</div>
					</div>
					<div class="flex-row-center-center manyBtn margin-b-10" v-if="item.manyShow">
						<el-button type="primary" @click="manySbmite">提交</el-button>
						<el-button  @click="Clickcancellation(item)">取消</el-button>
					</div>
					
				</div>
				<div  class="padding-t-10 padding-r-10" v-if="item.manyName !=undefined &&item.manyName !=''">	
					<div class="right-box pointer-color-theme color666 manySelect" @click="manyClick(item)">{{item.manyName}}</div>
				</div>
				<div  class="padding-t-10 padding-r-10" v-else>
					<div class="right-box pointer-color-theme color666"  style='width: 45px'  ></div>
				</div>
			</div>
		</div>
		<!--导航 -->
		<div>
			<el-table :data="tableData" max-height="100vh" border @selection-change="changeCheckBoxRow"
				v-loading="tableLoading" ref="myTable" element-loading-text="拼命加载中" :row-key="getRowKey"
				element-loading-spinner="el-icon-loading" :row-class-name="tableRowClassName"
				:highlight-current-row="true" :header-cell-style="{ 'text-align': 'center', background: '#f2f4fd' }">
				<el-table-column type="selection" width="50" align="center" fixed :reserve-selection="true" />
				<!-- <el-table-column label="查看" width="160" header-align="center" align="center">
					<template slot-scope="scope">
						<div class="demo-image__preview">
							<el-image style="width: 100px; height: 100px" :src="scope.row.fPicturePath"
								:preview-src-list="scope.row.srcslist">
							</el-image>
						</div>
					</template>
				</el-table-column> -->
				<el-table-column label="商品" align="left">
					<template slot-scope="scope">{{ scope.row.fGoodsFullName }}</template>
				</el-table-column>
				<el-table-column label="包装" align="center" width="80">
					<template slot-scope="scope">{{ scope.row.fGoodsPackingName}}</template>
				</el-table-column>
				<el-table-column label="当前价" align="center" width="160">
					<template slot-scope="scope">
						<span
							v-if='scope.row.fGoodsMinPrice!="" &&scope.row.fGoodsMinPrice == scope.row.fGoodsPrice ? false:true'>{{scope.row.fGoodsMinPrice |NumFormat}}~</span>{{scope.row.fGoodsPrice |NumFormat}}
					</template>
				</el-table-column>
			</el-table>
			<div class="margin-tb-10 flex-row-center-flex-end">
				<pagination :currentPage="currentPage" :pagesize="pagesize" :total="total"
					@handleCurrentChange="handleCurrentChange"></pagination>
			</div>
		</div>
		<div class="btn padding-tb-25">
			<el-button type="primary" @click="clickStep">下一步</el-button>
		</div>
	</div>
	<!-- 表格 -->
</template>
<script>
	import pagination from "../../../components/pagination/pagination";
	export default {
		components: {
			pagination
		},
		data() {
			return {
				selectedList:[],
				boxloading: false, // 控制导航的加载动画
				classifyList: [], //接受导航数据
				requestParam: [], //处理的参数
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 10,
				// 总条数
				total: 0,
				tableLoading: false, //表格加载动画
				tableData: [], //接受表格的数据
				tableHeight: 'calc(100vh - 257px)',
				tagsData: [], //表格选中数据
				activeTow: 2,
				showManySelect:false, //控制取消和提交按钮的显示与隐藏
				manyObj:{}
			}
		},
		mounted() {
				this.getGodPro()	
		},
		watch:{
        'selectedList':{
			handler(val){
				this.selectedList=val;
			},deep:true
		},
			
		},
		methods: {
			// 内部提交
			manySbmite(){
			   this.classifyList.forEach((item,index)=>{
				 if(item.fClassPropertieID == this.manyObj.fClassPropertieID){
					 item.goodsClassPropertieDatas.forEach(itemdatas=>{
						this.selectedList.forEach(itemDatas2=>{
							if(itemdatas.fClassPropertieDataID ==itemDatas2){
								itemdatas.selected=true
							}
						 })
					 })
				 }
			   })
			   this.getTableList(1)
			   
			},
			// 内部取消
			Clickcancellation(val){
				this.classifyList.forEach(item => {
					if(item.fClassPropertieID !='-1'){
						if(val.fClassPropertieID ==item.fClassPropertieID){
						       item.goodsClassPropertieDatas.splice(0,0,{
						       fClassPropertieData: "全部",
						       fClassPropertieDataID: "-1",
						       fClassPropertieID:item.fClassPropertieID,
							   selected:false
							   
						       })
							   let isAll = item.goodsClassPropertieDatas.findIndex(item3 => {
							   	return item3.fClassPropertieDataID != '-1' && item3.selected == true
							   })
							   if (isAll == -1) {
							   	item.goodsClassPropertieDatas[0].selected =  true
							   } 
							   else {
							   	item.goodsClassPropertieDatas[0].selected= false
							   }
								item.manyShow=false
							}
						
					}
					});
			},
			// 多选的点击事件
			manyClick(val){
				this.manyObj =val
				this.classifyList.forEach(item => {
					if(val.fClassPropertieID ==item.fClassPropertieID){
						item.goodsClassPropertieDatas.forEach((item1)=>{
							if(item1.fClassPropertieData == "全部" &&  item1.fClassPropertieDataID=="-1"){
								if(item.goodsClassPropertieDatas.length!=0){
									item.goodsClassPropertieDatas.splice(0,1)
								}	
							}
						})
						item.manyShow=true
					}else{
						if(item.fClassPropertieID !='-1'){
							if(item.fClassPropertieID != val.fClassPropertieID){
								if(item.goodsClassPropertieDatas[0].fClassPropertieData != "全部" && item.goodsClassPropertieDatas[0].fClassPropertieDataID !="-1"){
									item.goodsClassPropertieDatas.splice(0,0,{
									fClassPropertieData: "全部",
									fClassPropertieDataID: "-1",
									fClassPropertieID: item.fClassPropertieID,
									selected:false
									})
									// 如果没有符合条件的元素返回 -1
									let isAll = item.goodsClassPropertieDatas.findIndex(item3 => {
										return item3.fClassPropertieDataID != '-1' && item3.selected == true
									})
									if (isAll == -1) {
										item.goodsClassPropertieDatas[0].selected =  true
									} 
									else {
										item.goodsClassPropertieDatas[0].selected= false
									}
								}
							}
							item.manyShow=false
						}
					}
				});
				this.$forceUpdate()		
			},
			//点击单个的提交刷新底下的数据
			//获取导航
			async getGodPro() {
				this.boxloading = true;
				this.ApiRequestPost('/api/mall/goods/goods-class/get-contract-goods-navigations', {}).then(res => {
						this.classifyList = res.obj.goodsClasses;
						this.classifyList.forEach(item => {
							item.manyName ="多选"
							item.manyShow=false
							item.goodsClassPropertieDatas.forEach(item1 => {
								// 每一项加选中的属性,默认全部是选中的
								if (item1.fClassPropertieDataID == '-1') {
									item1.selected = true;
								} else {
									item1.selected = false;
								}
							});
						});
						this.boxloading = false
						this.getTableList()
					},
					Error => {
						this.boxloading = false
					})

			},
			//二级的点击事件
			cate3SelectClick(val) {
				if(val.fClassPropertieID=="-1"){
						this.classifyList[0].goodsClassPropertieDatas.forEach(valItem1=>{
							if(valItem1.fClassPropertieDataID == val.fClassPropertieDataID){
								valItem1.selected =true
								this.selectedList=[valItem1.fClassPropertieDataID]
							}else{
								console.log(valItem1.fClassPropertieDataID)
								valItem1.selected =false
							}
						})
				}else{
				this.classifyList.forEach(item => {
					// 如果一级的id和点击数据里面的一级id相同
					if (item.fClassPropertieID == val.fClassPropertieID) {
								if (val.fClassPropertieDataID == '-1') {
									item.goodsClassPropertieDatas.forEach(item1 => {
									if(item1.fClassPropertieDataID == '-1'){
										   item1.selected = true
										}else{
											this.selectedList.forEach((valitem)=>{
												if(item1.fClassPropertieDataID ==valitem){
													let selectId1 = this.selectedList.indexOf(item1.fClassPropertieDataID)
													this.selectedList.splice(selectId1,1)
												}
											})
										item1.selected = false
										
										}
										
										})
								} else { //不等于-1时
								     if(val.selected){
										 item.goodsClassPropertieDatas.forEach(i => {
											 if(val.fClassPropertieDataID == i.fClassPropertieDataID){
												 i.selected=false
												 // indexOf方法查找到第一个传入的元素，并返回当前元素的索引，如果没找到就就返回-1。
												let selectId = this.selectedList.indexOf(i.fClassPropertieDataID)
												this.selectedList.splice(selectId,1)
											 }
										 })
								     }else{
										item.goodsClassPropertieDatas.forEach(x => {
											if(val.fClassPropertieDataID == x.fClassPropertieDataID){
												x.selected=true
												this.selectedList.push(x.fClassPropertieDataID)																					
											}										
										})
								     
								     }
									 // 如果没有符合条件的元素返回 -1
									let isAll = item.goodsClassPropertieDatas.findIndex(item3 => {
										return item3.fClassPropertieDataID != '-1' && item3.selected == true
									})
									if (isAll == -1) {
										item.goodsClassPropertieDatas[0].selected =  true
									} 
									else {
										item.goodsClassPropertieDatas[0].selected= false
									}
								}
					}
				});
				}
				this.$forceUpdate()
				this.getTableList(1)

			},
			// 请求表格数据
			getTableList(e) {
				if (e && e == 1) {
					// 当前页数
					this.currentPage = 1;
					// 每页条数
					this.pagesize = 10;
					// 总条数
					this.total = 0;
				}
				this.requestParam = []
				this.classifyList.forEach(item => {
					item.goodsClassPropertieDatas.forEach(item1 => {
						if (item1.selected == true && item1.fClassPropertieDataID != "-1") {
							  
							this.selectedList.forEach((valitem)=>{
								if(item1.fClassPropertieDataID ==valitem){
									this.requestParam.push({
										fGoodsClassID: item1.fGoodsClassID,
										fClassPropertieID: item1.fClassPropertieID,
										fClassPropertieDataID: item1.fClassPropertieDataID
									})   
								}
							})
						}
					});
				})
				this.tableLoading = true;
				this.boxloading=true;
				this.ApiRequestPostNOMess('/api/mall/ebsale/goods-price/get-contract-goodsList-by-goodsclass-new', {
					classPropertieDataSearchDtos: this.requestParam,
					pagedAndSortedDto: {
						skipCount: (this.currentPage - 1) * this.pagesize,
						maxResultCount: this.pagesize,
						filter: "",
						sorting: ""
					}
				}).then(result => {
						for (var i = 0; i < result.obj.goods.items.length; i++) {
							result.obj.goods.items[i].srcslist = [];
							result.obj.goods.items[i].srcslist.push(result.obj.goods.items[i].fPicturePath);
						}
						result.obj.goodsClasses.goodsClasses.forEach((item,index)=>{
							if(index !=0){
								item.manyName ="多选"
								item.manyShow=false
							}
						})
						this.classifyList =result.obj.goodsClasses.goodsClasses
						this.classifyList.forEach((itemList)=>{
							itemList.goodsClassPropertieDatas.forEach((itemDatas,indexDatas)=>{
								if(itemDatas.fClassPropertieDataID != '-1'){
									itemDatas.selected = false
								}else{
									itemDatas.selected = true
									}					
							})
							if(itemList.fClassPropertieID == "-1"){
							if(this.requestParam.length ==0){
									itemList.goodsClassPropertieDatas[0].selected=true;
									this.selectedList=[itemList.goodsClassPropertieDatas[0].fClassPropertieDataID]
							}else{
								console.log(this.requestParam)
								console.log(this.selectedList)
									this.requestParam.forEach((itemParams)=>{
										if(itemList.fClassPropertieID == itemParams.fClassPropertieID){
											itemList.goodsClassPropertieDatas.forEach(item1=>{
												if(item1.fClassPropertieDataID ==itemParams.fClassPropertieDataID){
													item1.selected=true
													}else {
													item1.selected=false
													}
											})	
											
										}
										
									})
							}
							}								
							this.requestParam.forEach((itemParams)=>{
								if(itemList.fClassPropertieID == itemParams.fClassPropertieID){
									itemList.goodsClassPropertieDatas.forEach(item1=>{
										this.selectedList.forEach((valitems)=>{
											if(item1.fClassPropertieDataID ==valitems){
												item1.selected=true
											}else if(item1.fClassPropertieDataID == '-1'){
												item1.selected=false
											}
										})
									})	
									
								}
								
							})
						})
						

						this.tableData = result.obj.goods.items;
						this.total = result.obj.goods.totalCount;
						this.tableLoading = false
						this.boxloading=false

					},
					error => {
						this.tableLoading = false
						this.boxloading=false
					})
			},
			// 分页事件
			handleCurrentChange(Current) {
				this.currentPage = Current;
				this.getTableList();
			},
			// 点击下一步按钮
			clickStep() {
				if (this.tagsData.length == 0) {
					this.$message("请先选择商品");
					return false;
				}
				this.$emit("change", this.activeTow, this.tagsData)
			},
			//表格斑马线
			tableRowClassName(rowIndex) {
				if (rowIndex.rowIndex % 2 === 0) {
					return 'warning-row'
				} else if (rowIndex.rowIndex % 2 === 1) {
					return 'success-row'
				}
			},
			// 行复选框选中事件
			changeCheckBoxRow(selection) {
				this.tagsData = selection;
			},
			getRowKey(row) {
				return row.fGoodsID;
			},





		}
	}
</script>
<style lang="scss" scoped>
	/deep/.el-table--border th.gutter:last-of-type{
		background-color: #f2f4fd;
	}
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.seachTitle {
		display: flex;
		justify-content: space-between;
	}

	.shopName {
		font-size: 15px;
		font-weight: 700;
	}

	.select-box {
		border-bottom: 1px dashed #eeedf2;
	}

	.select-left {
		min-height: 46px;
		height: auto;
		width: 118px;
	}

	.select-right {
		width: 936px;
	}

	.right-box {
		height: 26px;
		line-height: 26px;
	}

	.btn {
		display: flex;
		justify-content: center;

		/deep/.el-button {
			padding: 14px 183px !important;
		}

		/deep/.el-button--primary {
			background-color: #366CFC;
			border-color: #409EFF;
		}
	}

	.color666 {
		color: #666666;
	}

	/deep/.el-table .cell {
		font-weight: normal;
		color: #666666;
	}
	.manySelect{
		width: 45px;
		text-align: center;
		border: 1px solid #dad9d9;
		border-radius: 4px;
		// height: 26px;
	}
	.manyBtn{
		/deep/.el-button{
			padding: 7px 20px;
		}
	}
	
	.right-box{
		/deep/.el-checkbox-group{
			width: 101px ;
			min-width:101px;
			display: inline-block;
		}
		/deep/.el-checkbox__label{
			width: 70px !important;
			min-width:70px !important;
			display: inline-grid;
			white-space: pre-line;
			word-wrap: break-word;
			overflow: hidden;     
		}
	}
	
</style>
