<template>
	<!-- 历史申请合同 -->
	<div class="width-fill" style='height: 100%;'>
		<!-- 说明部分 -->
		<img :src="imgUrl" alt="" style=" width: 100%;height:178px;" class="margin-t-10">
		<!-- 说明部分 -->
		<div class="titleBox margin-tb-20">
			<div class="titleText">历史申请合同</div>
			<div style="margin-left: 3px;margin-right: 3px;line-height: 32px;width: 45px;" class="cursorp">年份：</div>
			 <el-select v-model="year" placeholder="请选择年份" style="width: 15%;" @change="customeChange">
			    <el-option v-for="(item,index) in optionsCustome" :key="index" :label="item" :value="item">
			    </el-option>
			 </el-select>
			<div class="search-box margin-l-10" style="width:50%">
				<el-input placeholder="请输入合同编码进行模糊搜索" class="output" v-model="search" @keyup.native.enter="getTableList(1)" clearable>
				</el-input>
				<div style="width:80px;height: 32px; border:1px solid #0173FE;background-color: #0173FE;line-height: 32px;color: #ffffff;text-align: center;"  @click="getTableList(1)" >搜索</div>
			</div>
		</div>
		<!-- 主内容区域 -->
		<div style='height: calc(100% - 380px);' v-loading="tableLoading" element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading">
			<div>
				<div class="itemCard " v-for="(item,index) in tableData">
					<div style="display: flex; justify-content: space-between;">
						<div class="contractBox">
							<svg-icon icon-class="hetong" class="contractIcon" />
						</div>
						<div class="flex-direction-justify-content margin-l-10">
							<div class="codeText ">
								合同编码：<span v-if="item.fBillTypeNumber == ''">-</span> <span>{{item.fBillTypeNumber}}</span>
							</div>
						</div>
					</div>
					<!-- 右边 -->
					<div style="display: flex;padding-right: 5px;width: 55%;">
						<div v-if='item.fApproveStatus == 1 || item.fApproveStatus == 255' class="flex-direction-justify-content rightText margin-l-19 " style="width: 150px;">
							申请日期：{{item.fCreateTime}}
						</div>
						<div  v-if='item.fApproveStatus == 254' class="flex-direction-justify-content rightText margin-l-19 " style="width: 150px;">
							签订日期：{{item.fCreateTime}}
						</div>
						<div class="flex-direction-justify-content margin-l-40" style="width:80px;">
							<div class="inReview  radius10" v-if='item.fApproveStatus == 1'>审核中</div>
							<div class="noPass   radius10" v-if="item.fApproveStatus == 255">不通过</div>
							<div class="pass   radius10" v-if='item.fApproveStatus == 254'>审核通过</div>
						</div>
						<div class="flex-direction-justify-content rightText margin-l-40 cursorp" style="width: 60px;"
							@click="clickDetail(item)" v-if=" item.fApproveStatus == 255 || item.fApproveStatus == 254">
							查看详情
						</div>
						<div class="flex-direction-justify-content rightText margin-l-40" style="width: 40px;">
							<div class="cursorp" @click.stop="getDowPdf(item)" v-if='item.fApproveStatus == 254'>下载
							</div>
							<div class="cursorp" @click.stop="deletebtn(item)" v-if="item.fApproveStatus == 255">删除
							</div>
						</div>
					</div>
					<!-- 右边 -->
				</div>
			</div>
		</div>
		<!-- 主内容区域 -->
		<div class="margin-tb-10 flex-row-center-flex-end">
			<pagination :currentPage="currentPage" :pagesize="pagesize" :total="total"
				@handleCurrentChange="handleCurrentChange"></pagination>
		</div>
		<el-dialog v-dialogDrag :visible.sync="nopassVisible" width="570px" :before-close="handleClose"
			:close-on-press-escape="false" :close-on-click-modal="false" v-loading='loadingDialog'
			element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
			<div ref="dialogBox" class="dialogScroll">
				<div style="display: flex;justify-content: center;">
					<img src="../../../assets/shibai.png" alt="" style=" width:223px;height:178px;">
				</div>
				<div class="size-16">审核失败</div>
				<div class="size-14 ">你提交的内容可能不符合条件，导致审核不通过</div>
				<!-- 审批意见 -->
				<div style="display: flex;justify-content: center;" class="margin-t-20">
					<div class="opinion padding-lr-15 padding-tb-15">
						<div>审批意见：</div>
						<div>{{FPreviousRemark}}</div>
					</div>
				</div>
				<!-- 审批意见 -->
			</div>
			<div class="btn margin-t-30" style="display: flex;justify-content: center;">
				<el-button size="small" @click="clickHistory">查看历史合同</el-button>
				<el-button size="small" type="primary" @click="newShopType">重新申请</el-button>
			</div>
		</el-dialog>
		<el-dialog v-dialogDrag :visible.sync="passVisible" width="960px" :before-close="passhandleClose" 
			:close-on-press-escape="false" :close-on-click-modal="false" v-loading='passloadingDialog'
			element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
			<div ref="dialogBox" class="dialogScroll">
				<div style="display: flex;justify-content: center;">
					<img src="../../../assets/tongguo.png" alt="" style=" width:150px;height:138px;">
				</div>
				<div class="size-16">审核通过</div>
				<div class="size-14 margin-b-15">你提交的线上合同，合同编码为：{{tableDataCustome.fBillTypeNumber}}</div>
				<!-- 合同的内容 -->
				<el-row :gutter="5" style="background-color: #f2f4fd;padding: 15px;border-radius: 4px;" class="margin-b-15">
					    <el-col :span="12">
					    	<div  class="flex-row">
					    		<div class="color000000 margin-b-5  hide-text line-height35" style="font-weight: bold;min-width: 80px;display: flex; justify-content: space-between;">
					    			<div>合</div>
					    			<div>同</div>
					    			<div>编</div>
					    			<div>码：</div>
					    		</div>
					    		<div class="  color000000 margin-b-5 width-505 hide-text line-height35">{{ tableDataCustome.fBillTypeNumber}}</div>
					    	</div>
					    </el-col>
						<el-col :span="12">
							<div  class="flex-row">
								<div class="color000000 margin-b-5  hide-text line-height35" style="font-weight: bold;min-width: 80px;display: flex; justify-content: space-between;">
									<div>签</div>
									<div>订</div>
									<div>日</div>
									<div>期：</div>
								</div>
								<div class="color000000 margin-b-5 width-505 hide-text line-height35">{{ tableDataCustome.fBeginDate}}</div>
							</div>
						</el-col>
						<el-col :span="12" >
							<div  class="flex-row">
								<div class="color000000 margin-b-5 line-height35 hide-text" style="font-weight: bold;width: 80px;display: flex; justify-content: space-between;">
									<div>结</div>
									<div>束</div>
									<div>日</div>
									<div>期：</div>
								</div>
								<div class="color000000 margin-b-5 width-505 hide-text line-height35">{{tableDataCustome.fEndDate}}</div>
							</div>
						</el-col>
					<!-- </div> -->
					<el-col :span="12">
						<div  class="flex-row">
						  <div class="color000000 margin-b-5 color hide-text line-height35" style="font-weight: bold;width: 80px;display: flex; justify-content: space-between;">
								<div>合</div>
								<div>计</div>
								<div>金</div>
								<div>额：</div>
							</div>
							<div class="color000000 margin-b-5 width-505 hide-text line-height35">{{tableDataCustome.fMoneyTaxSumText}}</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div  class="flex-row">
						  <div class="color000000 margin-b-5  hide-text line-height35" style="font-weight: bold;width: 80px;display: flex; justify-content: space-between;">
								<div>联</div>
								<div>系</div>
								<div>人：</div>
							</div>
							<div class="color000000 margin-b-5 width-505 hide-text line-height35">{{tableDataCustome.fContact}}</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div  class="flex-row">
						  <div class="color000000 margin-b-5  hide-text line-height35" style="font-weight: bold;width: 80px;display: flex; justify-content: space-between;">
								<div>联</div>
								<div>系</div>
								<div>电</div>
								<div>话：</div>
							</div>
							<div class="color000000 margin-b-5 width-505 hide-text line-height35">{{tableDataCustome.fPhone}}</div> 
						</div>
					</el-col>
					<el-col :span="12">
						<div  class="flex-row">
						  <div class="color000000 margin-b-5  hide-text" style="font-weight: bold;width: 80px;display: flex; justify-content: space-between;">
								<div>微</div>
								<div>信</div>
								<div>号</div>
								<div>码：</div>
							</div>
							<div class="color000000 margin-b-5 width-505 hide-text">{{tableDataCustome.fWxCode}}</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div  class="flex-row">
						  <div class="color000000 margin-b-5  hide-text" style="font-weight: bold;width: 80px;display: flex; justify-content: space-between;">
								<div>电</div>
								<div>子</div>
								<div>邮</div>
								<div>箱：</div>
							</div>
							<div class="color000000 margin-b-5 width-505 hide-text">{{tableDataCustome.fElecMail}}</div>
						</div>
					</el-col>
					<el-col :span="24">
						<div  class="flex-row">
						  <div class="color000000 margin-b-5   line-height35" style="font-weight: bold;width: 80px;display: flex; justify-content: space-between;">
								<div>送</div>
								<div>达</div>
								<div>地</div>
								<div>址：</div>
							</div>
							<div class="color000000 margin-b-5 line-height35">{{tableDataCustome.fAddress}}</div>
						</div>
					</el-col>
				</el-row>
				<el-table :data="contractDetailData" max-height="calc(70vh - 445px)" border
					class="LRcustomer-table" v-loading="tableDataCustomeLoading" ref="multipleTable" element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading" :row-class-name="tableRowClassName" :highlight-current-row="true"
					:header-cell-style="{ 'text-align': 'center', background: '#f2f4fd',height: '20px',padding:'6px 0', }"
					header-align="center">
					<el-table-column label="商品" align="left">
						<template slot-scope="scope">{{ scope.row.fGoodsFullName }}</template>
					</el-table-column>
					<el-table-column label="包装" align="center" width="80">
						<template slot-scope="scope">{{ scope.row.fGoodsPackingName}}</template>
					</el-table-column>
					<el-table-column label="单价(元/吨)" align="center" width="220">
						<template slot-scope="scope">
							{{scope.row.fGoodsPrice |NumFormat}}
						</template>
					</el-table-column>
					<el-table-column label="数量(吨)" align="center" width="160">
						<template slot-scope="scope">
							{{scope.row.fAmount |NumFormat}}
						</template>
					</el-table-column>
				</el-table>
				<!-- 合同的内容 -->
			</div>
			<div class="btn2 margin-t-20" style="display: flex;justify-content: center;">
				<el-button  style="font-size: 14px;" type="primary" size="small" @click="getDowPdf(tableDataCustome)">下载</el-button>
				<el-button  style="font-size: 14px;" size="small" @click="passhandleClose">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pagination from "../../../components/pagination/pagination";
	export default {
		components: {
			pagination
		},
		data() {
			return {
				imgUrl: "",
				year:"",
				optionsCustome:[], //接收年份
				search: "", //模糊查询
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 5,
				// 总条数
				total: 0,
				tableData: [],
				tableLoading: false, //表格加载动画动画
				nopassVisible: false, //控制审核不通过的显示与隐藏
				passVisible:false, //控制审核通过的弹窗
				loadingDialog: false, //审核不通过的弹窗动画
				showName: "合同模板",
				FPreviousRemark: "", //审批意见
				tableDataCustome: {}, //接受表格的数据
				contractDetailData:[],
				tableDataCustomeLoading:false, //控制合同内容的加载动画
				ID:"",
				passloadingDialog:false, //控制审核通过弹窗内容的动画
			}
		},
		mounted() {
			this.getCustomeYear(); //获取合同的年份
			this.getImg(); //请求合同说明
		},
		methods: {
			//获取摘要
			getContract(){
				this.passloadingDialog =true
				this.ApiRequestPostNOMess('/api/mall/ebsale/elec-contract/get',{
					id:this.ID
				}).then(result=>{
					console.log(result)
					this.tableDataCustome=result.obj
					console.log(this.tableDataCustome.fAddress.length)
					this.tableDataCustome.fBeginDate =this.getDate(this.tableDataCustome.fBeginDate)
					this.tableDataCustome.fEndDate=this.getDate(this.tableDataCustome.fEndDate)
					this.contractDetail()
					this.passloadingDialog=false
				},
				error=>{
					this.contractDetail()
					this.passloadingDialog=false
				})
			},
			
			contractDetail(){
				this.passloadingDialog =true
				this.ApiRequestPostNOMess('/api/mall/ebsale/elec-contract-detail/get-list',{
					fElecContractID:this.ID
				}).then(result=>{
					console.log(result)
					this.contractDetailData=result.obj.items;
					this.passloadingDialog=false
				},
				error=>{
					this.passloadingDialog=false
				})
			},
			//表格斑马线
			tableRowClassName(rowIndex) {
				if (rowIndex.rowIndex % 2 === 0) {
					return 'warning-row'
				} else if (rowIndex.rowIndex % 2 === 1) {
					return 'success-row'
				}
			},
			// 获取申请合同的年份
			getCustomeYear(){
				this.ApiRequestPostNOMess('api/mall/ebsale/elec-contract/get-custome-year',{
					
				}).then(result=>{
					console.log(result)
					this.optionsCustome=result.obj
					this.year=this.optionsCustome[0];
					this.getTableList();
				})
			},
			// 年份下拉框的事件
			customeChange(val){
				console.log(val)
				this.year=val;
				this.getTableList();
			},
            // 获取合同说明图
			getImg() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-elec-contract-pic', {

				}).then(res => {
					this.imgUrl = res
				})
			},
			//点击查看历史合同按钮
			clickHistory() {
				this.nopassVisible = false;
				this.getTableList();
			},
			//点击重新请按钮
			newShopType() {
				this.nopassVisible = false;
				this.showName = "合同模板";
				this.$emit('change', this.showName)
			},
			// 点击查看详情
			clickDetail(item) {
				if (item.fApproveStatus == 255) {
					//审核状态为不同意时
					this.nopassVisible = true;
					this.FPreviousRemark=item.fDisagreeReason
				} else if (item.fApproveStatus == 254) {
					this.passVisible=true;
					this.ID =item.fElecContractID
					this.getContract()
				}

			},
			// 关闭审核通过的弹窗
			passhandleClose(){
				this.passVisible=false;
				this.getTableList()
			},
			// 取消审核不通过的弹窗
			handleClose() {
				this.nopassVisible = false;
				this.getTableList()
			},
			// 点击下载合同
			getDowPdf(value) {
				this.tableLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebsale/elec-contract/down-pdf', {
					fElecContractID: value.fElecContractID,
					frv: value.frv,
					rowStatus: value.rowStatus

				}).then(result => {
					window.open(result.obj, '_blank')
					this.tableLoading = false;
				},
				error=>{
					this.tableLoading = false;
				})
			},
			//删除数据
			deletebtn(i) {
				const deleteObj = {
					fElecContractID: i.fElecContractID,
					frv: i.frv,
					rowStatus: i.rowStatus
				};
				this.$confirm('要删除信息, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost('/api/mall/ebsale/elec-contract/delete', deleteObj).then(
						result => {
							this.getTableList();
						},
						rej => {}
					);
				}).catch(() => {});


			},
			// 请求表格数据
			getTableList(e) {
				if (e && e == 1) {
					// 当前页数
					this.currentPage = 1;
					// 每页条数
					this.pagesize = 5;
					// 总条数
					this.total = 0;
				}
				this.tableLoading = true;
				this.ApiRequestPostNOMess('/api/mall/ebsale/elec-contract/get-list-by-cutsomer', {
					fYear:this.year,
					filter: this.search,
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize,
				}).then(result => {
						result.obj.items.forEach((item) => {
							item.fCreateTime = this.getDate(item.fCreateTime)
						})
						this.tableData = result.obj.items;
						this.total = result.obj.totalCount;
						this.tableLoading = false

					},
					error => {
						this.tableLoading = false
					})
			},
			// 分页事件
			handleCurrentChange(Current) {
				this.currentPage = Current;
				this.getTableList();
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-table .cell {
		font-weight: normal;
		color: #000000;
	}
	.color000000{
	    color: #000000;
	}
	/deep/.el-table--border th.gutter:last-of-type{
		background-color: #f2f4fd;
	}
	.width-505 {
		width: 80%;
		// border: 1px solid #0173FE;
	}
	.margin-l-40{
		margin-left: 40px;
	}
	.output{
		/deep/.el-input__inner{
			height: 34px !important;
			border: 1px solid #e4e4e4 !important;
			border-radius: 0px;
		}
	}
	.cursorp {
		cursor: pointer;
		/*鼠标变小手*/
	}

	.titleBox {
		display: flex;
		/deep/.el-input__inner{
			height: 34px !important;
			border: 1px solid #e4e4e4 !important;
		}
	}

	.titleText {
		width: 30%;
		font-size: 14px;
		font-weight: 700;
		line-height: 32px;
	}

	.search-box {
		display: flex;
	}

	.itemCard {
		width: 100%;
		height: 62px;
		box-sizing: border-box;
		display: inline-block;
		// background-color: #f2f4fd;
		// box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.0980392156862745);
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #e4e4e4;
	}

	.contractBox {
		display: flex;
		flex-direction: column;
		justify-content: center;
		// align-items: center;
	}

	.contractIcon {
		width: 30px;
		height: 22px;
	}

	.flex-direction-justify-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		// align-items: center;
	}

	.codeText {
		font-size: 14px;
		line-height: 22px;
		color: #000000;
	}

	.rightText {
		font-size: 14px;
		line-height: 22px;
		color: #67748f;
	}

	.noPass {
		width: 70px;
		height: 22px;
		border: 1px solid #666;
		color: #666;
		text-align: center;
		line-height: 22px;
		padding: 2px 0;
	}

	.pass {
		width: 70px;
		height: 22px;
		border: 1px solid #0173FE;
		color: #0173FE;
		text-align: center;
		line-height: 22px;
		padding: 2px 0;
	}

	.inReview {
		width: 70px;
		height: 22px;
		color: #0173FE;
		text-align: center;
		line-height: 22px;
		padding: 2px 0;
	}

	.size-16 {
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		line-height: 50px;
		color: #000000;
	}

	.size-14 {
		font-size: 14px;
		text-align: center;
		color: #000000;
		// line-height: 70px;
	}

	.opinion {
		width: 409px;
		// height: 67px;
		background: #dcdfe2;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.0980392156862745);
	}

	.btn {
		display: flex;
		justify-content: center;

		/deep/.el-button {
			padding: 15px 75px !important;
			border-color: #409EFF;
			color: #409EFF;
			;
		}

		/deep/.el-button--primary {
			background-color: #366CFC;
			border-color: #409EFF;
			color: #ffffff;
		}
	}
    .btn2 {
		display: flex;
		justify-content: center;

		/deep/.el-button {
			padding: 15px 100px !important;
			border-color: #409EFF;
			color: #409EFF;
			;
		}

		/deep/.el-button--primary {
			background-color: #366CFC;
			border-color: #409EFF;
			color: #ffffff;
		}
	}
	// =======================================================

	.iconStyle {
		width: 20px;
		height: 20px;
	}



	.width-138 {
		width: 138px;
	}

	/deep/.el-input__icon {
		line-height: 32px;
	}
	.el-table {
		display: flex;
		flex-direction: column;
	}
	
	/deep/.el-table__header-wrapper {
		overflow: visible !important;
	}
	
	.LRcustomer-table /deep/thead tr th.is-leaf {
		border-right: none;
		border-bottom: none;
		border-right: none;
	}
	
	.el-table--border,
	.el-table--group {
		border: none;
	}
	
	.el-table--border::after,
	.el-table--group::after {
		width: 0;
	}
	
	.LRcustomer-table::before {
		width: 0;
	}
	
	.LRcustomer-table .el-table__fixed-right::before,
	.el-table__fixed::before {
		width: 0;
	}
	
	.LRcustomer-table .el-table td,
	.el-table th.is-leaf {
		// border-right: none;
		border-right: transparent;
	}
	/deep/.el-table--border td,
	.el-table--border th,
	.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: transparent !important;
		z-index: 10;
	}
	/deep/.el-table td,
	.el-table th {
		padding: 5px 0;
	}
	
	/deel/.el-table td,
	.el-table th {
		padding: 5px 0;
	}
	
	/deep/.el-table--border td,
	.el-table--border th,
	.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: none;
	}
	
	/deep/.el-table--border td,
	.el-table--border th,
	.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: none;
	}
</style>
